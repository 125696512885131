module.exports = {
  ENV: 'prod',
  APP_NAME: 'dioneo',
  END_POINT: 'https://ssm.services.apihf.com',
  API_KEY: 'AvrnCQJt9Z9SoBoTZo5nxcPxMngAaLg7QwrctEa7',
  BRAND_KEY: '3b375c31-9863-4af1-9bb9-1b30a76b7ebe',
  CLIENT_ID: '2hcnhauq2qob6f67q6ikcb7of1',
  USER_POOL_ID: 'eu-west-1_HIn9p5IYR',
  HEADER_MESSAGE_SIZE: 41,
  AGENTS_GROUP: 'DIONEO_AGENTS',
}